@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');


body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

nav {
  background-color: #152b14;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav a {
  color: white;
  text-decoration: none;
  margin: 0 10px;
}

h1 {
  font-size: 24px;
}

.button {
  background-color: #152b14;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

.contact form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: auto;
}

.contact form div {
  margin-bottom: 15px;
}

.contact form label {
  margin-bottom: 5px;
  font-weight: bold;
}

.contact form input,
.contact form textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
}

.contact form button {
  background-color: #152b14;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

.contact form button:hover {
  background-color: #152b14;
}
